.gallery{
    .images{
        margin-bottom: 2rem;
        position: relative;
        img{
            border: 1px solid #dedede;
            width: 90%;
            height: 300px;
            box-shadow: 0 0 4px #dedede8c;
        }
        .la-trash{
            right: 35px;
        }
    }
    .pdfs{
        text-align: center;
        margin-bottom: 3rem;
        a{
            display: flex;
            flex-direction: column;
            border: 1px solid;
            border-radius: 5px;
            width: min-content;
            padding-bottom: 8px;
            margin: auto;
        }
        .la-file-pdf{
            font-size: 10rem;
        }
        .la-trash{
            right: 32px;
            top: -15px;               
        }
    }
    .videos{
        text-align: center;
        position: relative;
        margin-bottom: 3rem;
        video{
            width: 90%;
            height: 350px;
            border: 1px solid #dedede
        }
        .la-trash{
            right: 22px;
            top: -10px;
        }
    }
    .la-trash{
        font-size: 1.5rem;
        background: var(--danger);
        color: #fff;
        border-radius: 50%;
        padding: 5px;
        position: absolute;
        cursor: pointer;
    }
}