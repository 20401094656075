.table-body{
    td{
        padding: 12px 20px!important;
    }
}
.addActivity{
.avatar-preview2{
    background-color: hsl(0, 0%, 90%) !important;
    height: 9rem!important;
    width: 9rem!important;
    position: relative;
    border-radius: 30px;
    border: 3px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    & > div {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
    }
    img {
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}
  
@media (max-width: 768px) {
    .avatar-preview2 {
        height: 6rem!important;
        width: 6rem!important;
        img{
            width: 75%!important;
        }
    }
}
@media (max-width: 500px) {
    .avatar-preview2 {
        height: 6rem!important;
        width: 100%!important;
        img{
            width: 75%!important;
        }
    }
}
}

.upload-gallery{
    .custum-file-upload {
        height: 80px;
        // width: 300px;
        display: flex;
        // flex-direction: column;
        // align-items: space-between;
        gap: 20px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border: 2px dashed #cacaca;
        background-color: rgba(255, 255, 255, 1);
        padding: 1.5rem;
        border-radius: 10px;
        box-shadow: 0px 48px 35px -48px rgba(0,0,0,0.1);
      }
      
      .custum-file-upload .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .custum-file-upload .icon svg {
        height: 40px;
        fill: rgba(75, 85, 99, 1);
      }
      
      .custum-file-upload .text {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .custum-file-upload .text span {
        font-weight: 400;
        color: rgba(75, 85, 99, 1);
      }
      
      .custum-file-upload input {
        display: none;
      }
}