.tabs-div {
    padding-bottom: 5px;
    border-bottom: 1px solid #e8eaeb;

    span {
      padding: 6px 32px;
      font-size: 16px;
      cursor: pointer;
      font-weight: 500;
    }
}
