.team-gallery{
    .images{
        margin-bottom: 2rem;
        position: relative;
        img{
            border: 1px solid #dedede;
            width: 90%;
            height: 300px;
            box-shadow: 0 0 4px #dedede8c;
        }
        .la-trash{
            right: 35px;
        }
    }

    .la-trash{
        font-size: 1.5rem;
        background: var(--danger);
        color: #fff;
        border-radius: 50%;
        padding: 5px;
        position: absolute;
        cursor: pointer;
    }
}